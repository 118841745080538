
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageButton } from "@/core/helpers/toolbar";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";
import KTAnswerCard from "@/views/exercise/AnswerCard.vue";

export default defineComponent({
  name: "modal-card",
  props: {
    image: String,
    modalId: String
  },
  components: {
    KTAnswerCard
  },
  data() {
    return {
      path: "",
      book_id: "",
      exercise_type: "",
      question_type: "", //用于按题型练习
      group_id: "", //用于按章节练习
      is_preview: "0",
      row: {},
      index: 0,
      questionIndexArray: [],
      totalCount: 0,
      item: {},
      single_option_no: -1, //单选题
      multiple_option_no_array: [], //多选题
      judge_answer: "", //判断题
      essay_content_answer: "", //问答题
      d: "",
      h: "",
      m: "",
      s: "",
      timer: 0
    };
  },
  methods: {
    /**
     * 初始化选项
     */
    initOptionNo() {
      this.single_option_no = -1;
      this.multiple_option_no_array = [];
      this.judge_answer = "";
      this.essay_content_answer = "";
    },

    /**
     * 答题卡
     */
    handleAnswerCard() {
      let row = {
        book_id: this.book_id,
        exercise_type: this.exercise_type,
        question_type: this.question_type,
        group_id: this.group_id
      };
      (this.$refs.AnswerCard as any).initData(row, false);
    },

    /**
     * 交卷
     */
    handleHandIn() {
      (this.$refs.AnswerCard as any).initData(this.row, true);
    },

    /**
     * 练习准备
     */
    exerciseReady() {
      const data = {
        book_id: this.book_id
      };
      ApiService.post("Exercise/ExerciseReady", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.getQuestionIndex();
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 获取试题索引数组
     */
    getQuestionIndex() {
      const data = {
        book_id: this.book_id,
        exercise_type: this.exercise_type,
        question_type: this.question_type,
        group_id: this.group_id
      };
      ApiService.post("Exercise/QuestionIndex", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.questionIndexArray = data.data;
            this.totalCount = this.questionIndexArray.length;
            if (this.totalCount > 0) {
              this.getQuestionDetail();
            }
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 获取试题明细
     */
    getQuestionDetail() {
      this.initOptionNo();
      let book_id = this.book_id;
      let index = this.index;
      let row = this.questionIndexArray[index];
      let question_id = (row as any).question_id;
      const data = {
        book_id: book_id,
        question_id: question_id
      };
      ApiService.post("Exercise/Question", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.item = data.data;
            if ((this.item as any).question_exercise.question_type == "00") {
              let single_exercise_list = (this.item as any)
                .single_exercise_list;
              for (let i = 0; i < single_exercise_list.length; i++) {
                if (single_exercise_list[i].is_checked_answer == true) {
                  this.single_option_no = single_exercise_list[i].option_no;
                  break;
                }
              }
            } else if (
              (this.item as any).question_exercise.question_type == "01"
            ) {
              let multiple_exercise_list = (this.item as any)
                .multiple_exercise_list;
              for (let i = 0; i < multiple_exercise_list.length; i++) {
                if (multiple_exercise_list[i].is_checked_answer == true) {
                  (this.multiple_option_no_array as any).push(
                    multiple_exercise_list[i].option_no
                  );
                }
              }
            } else if (
              (this.item as any).question_exercise.question_type == "02"
            ) {
              if ((this.item as any).judge_exercise) {
                this.judge_answer = (
                  this.item as any
                ).judge_exercise.judge_answer;
              }
            } else if (
              (this.item as any).question_exercise.question_type == "04"
            ) {
              if ((this.item as any).essay_exercise) {
                this.essay_content_answer = (
                  this.item as any
                ).essay_exercise.essay_content_answer;
              }
            }
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 上一题
     */
    handlePrevious() {
      if (this.index > 0) {
        this.index = this.index - 1;
        this.getQuestionDetail();
      }
    },

    /**
     * 下一题
     */
    handleNext() {
      if (this.index + 1 < this.totalCount) {
        this.index = this.index + 1;
        this.getQuestionDetail();
      }
    },

    /**
     * 收藏
     */
    handleRemarkChange(e) {
      let book_id = this.book_id;
      let index = this.index;
      let row = this.questionIndexArray[index];
      let question_id = (row as any).question_id;
      let is_mark = e.currentTarget.checked ? "1" : "0";
      const data = {
        book_id: book_id,
        question_id: question_id,
        is_mark: is_mark
      };
      ApiService.post("Exercise/QuestionMark", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            Toast.success(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 单选题
     */
    handleSingleChange() {
      let book_id = this.book_id;
      let index = this.index;
      let row = this.questionIndexArray[index];
      let question_id = (row as any).question_id;
      let single_option_no = this.single_option_no;
      const data = {
        book_id: book_id,
        question_id: question_id,
        single_option_no: single_option_no
      };
      ApiService.post("Exercise/AnswerEdit", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.handleNext(); //自动下一题
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 多选题
     */
    handleMultipleChange() {
      let book_id = this.book_id;
      let index = this.index;
      let row = this.questionIndexArray[index];
      let question_id = (row as any).question_id;
      let multiple_option_no_array = this.multiple_option_no_array;
      const data = {
        book_id: book_id,
        question_id: question_id,
        multiple_option_no_array: JSON.stringify(multiple_option_no_array)
      };
      ApiService.post("Exercise/AnswerEdit", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            //Toast.success(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 判断题
     */
    handleJudgeChange() {
      let book_id = this.book_id;
      let index = this.index;
      let row = this.questionIndexArray[index];
      let question_id = (row as any).question_id;
      let judge_answer = this.judge_answer;
      const data = {
        book_id: book_id,
        question_id: question_id,
        judge_answer: judge_answer
      };
      ApiService.post("Exercise/AnswerEdit", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            //Toast.success(data.message);
            this.handleNext(); //自动下一题
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 填空题
     */
    handleFillChange(citem) {
      let book_id = this.book_id;
      let row = this.questionIndexArray[this.index];
      let question_id = (row as any).question_id;
      let fill_no = citem.fill_no;
      let fill_content_answer = citem.fill_content_answer;
      const data = {
        book_id: book_id,
        question_id: question_id,
        fill_no: fill_no,
        fill_content_answer: fill_content_answer
      };
      ApiService.post("Exercise/AnswerEdit", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            //Toast.success(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 问答题
     */
    handleEssayChange() {
      let book_id = this.book_id;
      let row = this.questionIndexArray[this.index];
      let question_id = (row as any).question_id;
      let essay_content_answer = this.essay_content_answer;
      const data = {
        book_id: book_id,
        question_id: question_id,
        essay_content_answer: essay_content_answer
      };
      ApiService.post("Exercise/AnswerEdit", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            //Toast.success(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },

    /**
     * 定义Bus
     */
    initBus() {
      /**
       * 定位题目
       */
      Bus.$on("handleLocation", (index) => {
        this.index = index;
        this.getQuestionDetail();
      });

      /**
       * 提交答卷
       */
      Bus.$on("handleSubmit", () => {
        let exam_id = (this.row as any).exam_id;
        const data = {
          exam_id: exam_id
        };
        ApiService.post("Exam/AnswerSubmit", data as AxiosRequestConfig)
          .then(({ data }) => {
            if (data.success == true) {
              Toast.success(data.message);
              this.$router.push({ name: "exam" });
            } else {
              Toast.warning(data.message);
            }
          })
          .catch((error) => {
            Toast.error(error.message);
          });
      });

      Bus.$on("handleBack", () => {
        this.$router.push({
          path: "/exercise/ready",
          query: { book_id: this.book_id, path: this.path }
        });
      });
    }
  },
  beforeUnmount() {
    Bus.$off("handleLocation");
    Bus.$off("handleSubmit");
    clearTimeout(this.timer);
  },
  mounted() {
    setCurrentPageBreadcrumbs("练习中", ["练习"]);
    setCurrentPageButton("back", true);
    this.initBus();
    this.path = this.$route.query.path as string;
    this.book_id = this.$route.query.book_id as string;
    this.exercise_type = this.$route.query.exercise_type as string;
    this.question_type = this.$route.query.question_type
      ? (this.$route.query.question_type as string)
      : "";
    this.group_id = this.$route.query.group_id
      ? (this.$route.query.group_id as string)
      : "";
    this.exerciseReady();
  }
});
